import React from "react"

import Layout from "../components/layout"
import { Link, useStaticQuery, graphql } from "gatsby"
import SEO from "../components/seo"
import GlobalTop from "../components/globalTop"

const Podologia = ({ pageContext }) => {
  const { title, acfPodologia, data, offer_name } = pageContext
  const fullSchema = JSON.parse(data.seo.schema.raw)

  const query = useStaticQuery(graphql`
    {
      wpChirurgiaPalca: allWpChirurgiaPalca {
        nodes {
          uri
          id
          slug
          title
          acfPodologia {
            podologiaGridIcon {
              sourceUrl
            }
          }
        }
      }
    }
  `)

  return (
    <Layout light>
      <SEO
        title={data.seo.title ? data.seo.title : title}
        description={data.seo.metaDesc}
      />
      <GlobalTop
        title={`${title} Kraków`}
        offer
        podologia={offer_name === "podologia"}
        chirurgiaPalca={offer_name === "chirurgia-palca"}
        light
      />

      <section className="offer-single-content offer-single-content--single">
        <div className="container">
          {acfPodologia.map(
            (
              {
                podologiaContentHeader,
                podologiaContentDescription,
                podologiaContentImage,
              },
              index
            ) => (
              <div key={index}>
                <div className="row align-items-center">
                  {index % 2 ? (
                    <>
                      <div className="col-md-5">
                        <img
                          className="img-fluid"
                          src={podologiaContentImage.sourceUrl}
                          alt={title}
                        />
                      </div>
                      <div className="col-md-6 offset-md-1">
                        <h2 className="section--header">
                          {podologiaContentHeader}
                        </h2>
                        <div
                          dangerouslySetInnerHTML={{
                            __html: podologiaContentDescription,
                          }}
                        />
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="col-md-6">
                        <h2 className="section--header">
                          {podologiaContentHeader}
                        </h2>
                        <div
                          dangerouslySetInnerHTML={{
                            __html: podologiaContentDescription,
                          }}
                        />
                      </div>
                      <div className="col-md-5 offset-md-1">
                        <img
                          className="img-fluid"
                          src={podologiaContentImage.sourceUrl}
                          alt={title}
                        />
                      </div>
                    </>
                  )}
                </div>
                <div className="divider"></div>
              </div>
            )
          )}
        </div>
      </section>
      {pageContext?.data?.slug === "wrastajacy-paznokiec" && (
        <section className="offer-single-services">
          <div className="container">
            <h2 className="section--header text-center">Usługi</h2>
            <div className="row">
              {query.wpChirurgiaPalca.nodes
                .filter(
                  item => item.slug === "plastyka-walow-okolopaznokciowych"
                )
                .map((node, index) => (
                  <div className="col-xl-4 col-lg-5 col-sm-6" key={index}>
                    <Link
                      to={`/oferta/chirurgia-palca${node.uri}`}
                      className="offer-single-services__tile"
                      style={{
                        backgroundImage: `url(${
                          node.acfPodologia.podologiaGridIcon.sourceUrl
                            ? node.acfPodologia.podologiaGridIcon.sourceUrl
                            : ""
                        })`,
                      }}
                    >
                      <h4>{node.title}</h4>
                    </Link>
                  </div>
                ))}
            </div>
          </div>
        </section>
      )}

      {fullSchema && (
        <script type="application/ld+json">
          {JSON.stringify({ ...fullSchema }, null, 2)}
        </script>
      )}
    </Layout>
  )
}

export default Podologia
